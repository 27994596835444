import Preloader from '@/components/Preloader';
import { NotificationProvider } from '@/hooks/NotificationHook';

const Layout = ({ children, ...properties }) => {
    return (
        <div {...properties}>
            <NotificationProvider>
                <Preloader />
                {children}
            </NotificationProvider>
        </div>
    );
};

export default Layout;
